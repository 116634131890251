import React from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import blogOne from "../../components/lottie/blogOne.json";

function Posts() {
  return (
    <div className="home">
      <div className="container">
        <div className="row">
          <div className="padding">
            <h1>Blogs</h1>
          </div>
        </div>
        <div className="row align-items-center my-5">
          <div className="col-lg-4">
            <Link to="/blog/how-to-market-cyber-security">
              <Lottie speed={0.25} animationData={blogOne} autoPlay={false} />
            </Link>
          </div>

          <div className="col-lg-8">
            <h2 className="font-weight-light">
              How To Market Cyber Security: Tips and Strategies{" "}
            </h2>
            <p>
              Knowledge is Power: Cyber Security Market Research The cyber
              security market has seen huge growth in recent years but during
              the same time we’ve witnessed new disruptive technologies come to
              the fore. Investment and spending in cyber security are at record
              levels as cybercriminals are becoming more sophisticated in
              finding new ways to steal data.
            </p>
            <Link to="/blog/how-to-market-cyber-security">
              <div className="flex-btns">
                <button className="btn-hero">Continue reading</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <div class="padding green">
              {" "}
              <h2>More blogs coming soon...</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Posts;
