import { FadeIn } from "./animations/Fade";
import { Logos } from "./animations/logos";
import TypewriterText from "./animations/typewriter";
import TypewriterTextPro from "./animations/typewriterpro";

import { useState, useEffect } from "react";
import Lottie from "lottie-react";
import plane from "./lottie/plane.json";
import profile from "./lottie/form.json";
import CookieConsent from "react-cookie-consent";
import React from "react";
import { Helmet } from "react-helmet";
function Home(props) {
  FadeIn();
  Logos();

  const words = ["content", "blogs", "social posts"];
  const [wordIndex, setWordIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFadingOut(true);
      setTimeout(() => {
        setWordIndex((wordIndex + 1) % words.length);
        setIsFadingOut(false);
      }, 1000);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [wordIndex, words.length]);

  const {
    siteTitle = "Home | Scribblii",
    metaDescription = "Scribblii is your AI assistant to help you create better content and help you grow your marketing practice",
  } = props;
  return (
    <div className="about">
      <Helmet>
        <title>{siteTitle}</title>
        <meta
          name="keywords"
          content="marketing,agency,b2b,security,desing,seo,content"
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={metaDescription}
        />
        <meta property="og:locale" key="og:locale" content="en_GB" />
        <meta name="description" content={metaDescription} />
        <meta property="og:title" key="og:title" content={siteTitle} />
        <meta
          property="og:image"
          key="og:image"
          content="https://nowcomms.com/wp-content/uploads/2021/11/thing.png"
        />
      </Helmet>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <div className="hero">
        <div className="container-fluid">
          <div className="row hero-row align-items-center ">
            <div className="col-lg-4 mob-hide">
              <div className="hoverEffects">
                <Lottie speed={0.25} animationData={plane} autoPlay={false} />
              </div>
            </div>
            <div className="col-lg-8 hero-content">
              <h1>
                Smarter{" "}
                <span
                  className={`animated-word ${
                    isFadingOut ? "fade-out" : "fade-in"
                  }`}
                >
                  {wordIndex === 0 ? "campaigns" : words[wordIndex - 1]}
                </span>{" "}
                <br />
                starts with <span className="white">Scribblii</span>
              </h1>

              <div className="flex-btns">
                <a className="btn-hero" href="/offer">
                  Start scribbliin!
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row section-row align-items-center">
            <div class="col-lg-6">
              <h2>Create content faster with Scribblii prompts</h2>
              <h3>
                Scribbllii learns your business and helps you create content
                that matches your sector, audience and brand personality.
              </h3>
              <hr />
              <h4>how it works</h4>
              <p>
                Create your profile and select your buzzwords that scribblii
                remembers and uses to build your content recommendations.
                Language model chat bots are great as long as they get asked the
                right questions. Sribblii preloads your prompt questions to
                create more relevant and faster results than you would using
                vanila content generators.
              </p>
            </div>
            <div className="col-lg-6">
              <Lottie speed={0.25} animationData={profile} autoPlay={false} />
            </div>
          </div>
          <div className="row section-row align-items-center">
            <div class="col-lg-12">
              <h2>
                {" "}
                <span className="white">Basic</span> features include
              </h2>
            </div>

            <div className="row section-row align-items-stretch">
              <div className="col-lg-6">
                <div className="input">
                  <h3>
                    Hey Scribblii, help me start a blog about how AI is going to
                    revolutionalise my industry...
                  </h3>
                  <hr />
                  <TypewriterText />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  {" "}
                  <div class="col-lg-6">
                    <h4>Blog blueprints</h4>
                    <p>
                      get instant and high quality content suggestions to help
                      you create your next engaging blog post. Or get help
                      refining or fixing existing content with perfect grammar
                      or change the tone of voices
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <h4>Image generation</h4>
                    <p>
                      Create royalty free images that you can use online to make
                      your content stand out from the crowd
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div class="col-lg-6">
                    <h4>SEO Optimisation</h4>
                    <p>
                      automatically create SEO content ready to deploy on your
                      website from meta descriptions, keyword clusters and
                      additional suggestions to better improve your website
                      visibility
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <h4>Topic assistant</h4>
                    <p>
                      get help when you're stuck with ideas or where to start.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <h2>
                <span className="white">Pro</span> features include
              </h2>
            </div>

            <div className="row section-row align-items-stretch">
              <div className="col-lg-6">
                <div className="input">
                  <h3>
                    Hey Scribblii, how did my latest blog post do according to
                    my google analytics?
                  </h3>
                  <hr />

                  <TypewriterTextPro />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  {" "}
                  <div class="col-lg-6">
                    <h4>Analytics</h4>
                    <p>
                      Get feed back and results so you can keep developing and
                      making better content
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <h4>Industry insights</h4>
                    <p>
                      See what other relevant Scribblii creators are making and
                      get in on the conversation
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div class="col-lg-6">
                    <h4>Wordpress Integration</h4>
                    <p>
                      Automatically post to your blog with the click of a button
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <h4>Resource center</h4>
                    <p>
                      get access to Scribblii insights and learning environment
                      that will get will help you build you brand, develop your
                      sales pipeline and everything you need to know about
                      successful marketing techniques
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row section-row align-items-stretch">
              <div className="col-lg-3">
                <h3>Analytics</h3>
                <p>
                  Get feed back and results so you can keep developing and
                  making better content
                </p>
              </div>
              <div className="col-lg-3">
                <h3>Industry insights</h3>
                <p>
                  See what other relevant Scribblii creators are making and get
                  in on the conversation
                </p>
              </div>

              <div className="col-lg-3">
                <h3>Wordpress Integration</h3>
                <p>
                  Automatically post to your blog with the click of a button
                </p>
              </div>
              <div className="col-lg-3">
                <h3>topic helper</h3>
                <p>get</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
