import React, { useState, useEffect, useRef } from "react";

function TypewriterTextPro() {
  const aText = [
    "I can analyze your Google Analytics results to help you understand how your recent blog posts have performed. Based on the data, it looks like your most recent post on [insert post topic] has performed well, with a high engagement rate and a low bounce rate. This indicates that the content was relevant and engaging to your target audience.",
    "However, I also noticed that some of your other posts have a higher bounce rate, suggesting that the content may not be as engaging or relevant to your audience. By identifying these trends, we can work together to refine your content strategy and create more engaging posts that resonate with your target audience.",
  ];

  const iSpeed = 50;
  const iScrollAt = 20;
  const [iIndex, setIndex] = useState(0);
  const [iTextPos, setTextPos] = useState(0);
  const [isVisible, setVisible] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.unobserve(targetRef.current);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isVisible) {
      interval = setInterval(() => {
        let sContents = " ";
        let iRow = Math.max(0, iIndex - iScrollAt);
        while (iRow < iIndex) {
          sContents += aText[iRow++] + "<br />";
        }
        const destination = document.getElementById("typedtextpro");
        destination.innerHTML =
          sContents + aText[iIndex].substring(0, iTextPos) + "_";
        if (iTextPos === aText[iIndex].length) {
          setTextPos(0);
          setIndex(iIndex + 1);
        } else {
          setTextPos(iTextPos + 1);
        }
      }, iSpeed);
    }
    return () => clearInterval(interval);
  }, [isVisible, iIndex, iTextPos]);

  return <div ref={targetRef} id="typedtextpro"></div>;
}

export default TypewriterTextPro;
