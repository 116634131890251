import React, { useState, useEffect, useRef } from "react";

function TypewriterText() {
  const aText = [
    "The rise of AI is set to revolutionize many industries, and the homeware sector is no exception. As a high street business that sells homeware, AI has the potential to offer many benefits, such as streamlining processes, enhancing the customer experience, and driving sales.",
    "One of the most significant ways that AI can revolutionize the homeware industry is through the use of chatbots. Chatbots can offer 24/7 customer support, answering customer queries and providing personalized product recommendations. They can also take orders and track deliveries, freeing up staff to focus on other areas of the business. AI-powered recommendation engines can also provide personalized product suggestions to customers, improving the overall shopping experience and driving sales.",
  ];

  const iSpeed = 50;
  const iScrollAt = 20;
  const [iIndex, setIndex] = useState(0);
  const [iTextPos, setTextPos] = useState(0);
  const [isVisible, setVisible] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.unobserve(targetRef.current);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isVisible) {
      interval = setInterval(() => {
        let sContents = " ";
        let iRow = Math.max(0, iIndex - iScrollAt);
        while (iRow < iIndex) {
          sContents += aText[iRow++] + "<br />";
        }
        const destination = document.getElementById("typedtext");
        destination.innerHTML =
          sContents + aText[iIndex].substring(0, iTextPos) + "_";
        if (iTextPos === aText[iIndex].length) {
          setTextPos(0);
          setIndex(iIndex + 1);
        } else {
          setTextPos(iTextPos + 1);
        }
      }, iSpeed);
    }
    return () => clearInterval(interval);
  }, [isVisible, iIndex, iTextPos]);

  return <div ref={targetRef} id="typedtext"></div>;
}

export default TypewriterText;
