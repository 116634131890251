import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

function Navigation() {
  <CookieConsent>
    This website uses cookies to enhance the user experience.
  </CookieConsent>;

  const location = useLocation();
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="no-flex">
                <NavLink className="navbar-brand" to="/">
                  Scribblii
                </NavLink>
              </div>
              <div className="tagline">
                {location.pathname === "/"
                  ? "your AI marketing toolkit"
                  : "AGILE CREATIVE MARKETING"}
              </div>
            </div>
            <div className="col-lg-8 flex-col">
              {" "}
              <ul className="navbar-nav ml-auto">
                <li className="nav-item nav-col">
                  <NavLink className="nav-link" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    Demo
                  </NavLink>
                </li>
                <li className="nav-item nav-col">
                  <NavLink className="nav-link" to="/">
                    Pricing
                  </NavLink>
                </li>

                {/* <li className="nav-item nav-col">
                  <a className="nav-link" href="/blog">
                    Blog
                  </a>
                </li> */}
                <li className="nav-item nav-col">
                  <NavLink className="nav-link" to="/">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-flex ">
            <ul class="navbar-nav ml-auto navbar-row">
              <li className="nav-item">
                <strong className="nav-link">Powered by</strong>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  className="nav-link"
                  href="https://www.nowcomms.com/"
                >
                  Now|Comms
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  className="nav-link"
                  href="https://www.nowcomms.com/"
                >
                  myMarvin
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
